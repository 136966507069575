window.MedadUtils = {
    reload_page: function() {
        setTimeout(() => {
            location.reload();
        }, 5000);
    },
    set: function (arr) {
        // set returns unique values

        var unique = [];
        arr.forEach(item => {
            if (unique.indexOf(item) === -1) unique.push(item);
        });
        return unique;
    },
    sum: function (arr) {
        // sum calculates the sum of all numbers in an array.

        var s = 0;
        arr.forEach(i => s += i);
        return s;
    },
    debounce: function (func, timeout) {
        // debounce will create a debounced function that will execute only once during a set time period regardless of how many times it is called.

        var intv;
        return function () {
            var self = this;
            var args = arguments;
            if (intv) {
                clearTimeout(intv);
                intv = null;
            }
            intv = setTimeout(function () {
                func.apply(self, args);
            }, timeout);
        };
    },
    pycall: function(method, args) {
    // pycall encapsulates frappe.call in a Promise to allow for async/await use.
      return new Promise(function(resolve, reject) {
        frappe.call({
          method,
          args,
          callback: function(r) {
            resolve(r.message);
          },
          error: function (e) {
            reject(e);
          }
        });
      });
    },
    pyfetch: async function (method, url, params) {
        try {
            const currentHost = window.location.origin;
            url = currentHost + "/api/method/" + url;

            const options = {
                method: method,
            };

            if (method === "POST") {
                options.headers = {
                    "Content-Type": "application/json",
                };
                options.body = JSON.stringify(params);
            } else if (method === "GET" && params) {
                url += "?" + new URLSearchParams(params).toString();
            }

            const response = await fetch(url, options);
            const data = await response.json();
            
            return data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    getQueryParam: function(name) {
        var regex = new RegExp(name + '=(.+?)(&|$)');
        var matches = regex.exec(window.location.search);
        if (matches && matches.length === 3) {
            return matches[1];
        }
        return null;
    },
    arrayInArray(item, arr) {
        for (var o = 0; o < arr.length; o++) {
            for (var i = 0; i < item.length; i++) {
                if (item[i] == undefined || arr[o][i] == undefined || item[i] !== arr[o][i]) {
                    break;
                }
                if (i === item.length-1) {
                    return o;
                }
            }
        }
        return -1;
    },
    get_linked_data: function(linkeds) {
    
        // Indicate the specific html values
        for(let i = 0;i<linkeds.length;i++){
          frappe.call({
            type: linkeds[i].type,
            method: "frappe.client.get_value",
            args: {
                'doctype': linkeds[i].doctype,
                'filters': linkeds[i].filters,
                'fieldname': linkeds[i].fieldnames
            },
            callback: function(res){
                $.each(res.message, function(key, value) {
                    if(linkeds[i].isfield){
                        $(linkeds[i].htmlvalue[key].wrapper).html(
                        ` 
                                        <div class="frappe-control input-max-width">
                                            <div class="form-group">
                                            <div class="clearfix">
                                                <label class="control-label" style="padding-right: 0px;"> `+key+` </label>
                                            </div>
                                            <div class="control-input-wrapper">
                                                <div class="control-input" style="display:none;">
                                                </div>
                                                <div class="control-value like-disabled-input">
                                                `+res.message[key]+` 
                                                </div>
                                                <p class="help-box small text muted"></p>
                                                </div>
                                            </div>
                                        </div>    
                        `
                            )
                    }
                    else
                        $(linkeds[i].htmlvalue[key].wrapper).html(
                            `
                            <div class="frappe-control input-max-width">
                                            <div class="form-group">

                                            <div class="control-input-wrapper">
                                                <div class="control-input" style="display:none;">
                                                </div>
                                                <div class="control-value like-disabled-input">
                                                `+res.message[key]+` 
                                                </div>
                                                <p class="help-box small text muted"></p>
                                                </div>
                                            </div>
                                        </div>
                            `
                            )

                    });
                }
            })
        }
    },
    convert_from_hijri_to_gregorian: function (hijri_date, input_format = "iYYYY-iMM-iDD") {
        if (!hijri_date) return "";
        moment_date = moment_hijri(hijri_date, input_format);
        return moment_date.format("YYYY-MM-DD");
    },
    convert_from_gregorian_to_hijri: function(gregorian_date, output_format = "iYYYY-iMM-iDD") {
        if (!gregorian_date) return "";
        moment_date = moment_hijri(gregorian_date, "YYYY-MM-DD");
        return moment_date.format(output_format);
    },
    set_gregorian_field_from_hijri_value: (gregorian_date_field, hijri_date_field_value) => {
        // const hijri_date = hijri_date_field.value;
        if (!hijri_date_field_value) return;
    
        // convert to gregorian
        const gregorian_date = MedadUtils.convert_from_hijri_to_gregorian(hijri_date_field_value);
        
        // set the gregorian date field
        gregorian_date_field.set_value(gregorian_date);
    },
    set_hijri_field_from_gregorian_value: (hijri_date_field, gregorian_date_field_value) => {
        // const gregorian_date = gregorian_date_field.value;
        if (!gregorian_date_field_value) return;
        
        // convert to hijri
        const hijri_date = MedadUtils.convert_from_gregorian_to_hijri(gregorian_date_field_value);

        // set the gregorian date field
        hijri_date_field.set_value(hijri_date);
    },
    is_hijri: async function() {
        const calendar_type = await frappe.db.get_single_value("Core Configuration", "calendar_type");
		const is_hijri = calendar_type === "Hijri Om-Qura";
        return is_hijri;
    },
    enable_hijri_dates: async function(frm, make_other_field_read_only = 0, make_other_field_hidden = 1, ignore_fields = []) {
        // get the calendar type from the core configuration 
		const calendar_type = await frappe.db.get_single_value("Core Configuration", "calendar_type");
		const is_hijri = calendar_type === "Hijri Om-Qura";

		// get date fields (not hijri)
		const gregorian_date_field_names = []
		Object.entries(frm.fields_dict).forEach(([key, value]) => {
			if (value.df.fieldtype == "Date" && !value.df.fieldname.endsWith("_hijri") && !ignore_fields.includes(value.df.fieldname)) {
				gregorian_date_field_names.push(value.df.fieldname);
			}
		});

		// hide and show depend on the calendar type ans set hijri caledar date
		gregorian_date_field_names.forEach((gregorian_date_field_name) => {
            const gregorian_date_field = frm.fields_dict[gregorian_date_field_name];
            const gregorian_date_field_value = frm.doc[gregorian_date_field_name];
			
            const hijri_date_field_name = gregorian_date_field_name + "_hijri";
            
            const is_custom_field = frappe.get_meta(frm.doctype).fields.filter(e => e.fieldname == gregorian_date_field_name)[0].is_custom_field
            if (!is_custom_field && !(hijri_date_field_name in frm.fields_dict)) {
                console.log("error!");
                frappe.throw(`The field ${gregorian_date_field_name} does not have a hijri equivalent!`);
            }
            const hijri_date_field = frm.fields_dict[hijri_date_field_name];
          
			if (is_hijri) {
				gregorian_date_field.df.hidden = make_other_field_hidden;
                gregorian_date_field.df.read_only = make_other_field_read_only;
                
                if (gregorian_date_field.df.reqd){
                    hijri_date_field.df.reqd = 1;
                }
                gregorian_date_field.df.reqd = 0;
			} else {
				hijri_date_field.df.hidden = make_other_field_hidden;
                hijri_date_field.df.read_only = make_other_field_read_only;
                
                if (hijri_date_field.df.reqd){
                    gregorian_date_field.df.reqd = 1;
                }
                hijri_date_field.df.reqd = 0;
			}

			// refresh hijri calendar
			MedadUtils.set_hijri_field_from_gregorian_value(hijri_date_field, gregorian_date_field_value);
            frm.refresh_fields();
		});

		// create functions that manipulate dates and assign them to the fields
		gregorian_date_field_names.forEach((gregorian_date_field_name) => {
			const hijri_date_field_name = gregorian_date_field_name + "_hijri";

			// get date and hijri date fields
			const gregorian_date_field = frm.fields_dict[gregorian_date_field_name];
			const hijri_date_field = frm.fields_dict[hijri_date_field_name];
			
            
			// create gregorian date function
			const gregorian_date_function = () => {
                if (is_hijri) return;
                
                const gregorian_date_field_value = frm.doc[gregorian_date_field_name];
				if (!gregorian_date_field_value) {
					hijri_date_field.set_value("");
					return;
				}

				// set hijri date field based on gregorian date
				MedadUtils.set_hijri_field_from_gregorian_value(hijri_date_field, gregorian_date_field_value);
				frm.refresh_fields();
			}

			// create hijri date function
			const hijri_date_function = () => {
				// to prevent loop
				if (!is_hijri) return;
				
                const hijri_date_field_value = frm.doc[hijri_date_field_name];
				if (!hijri_date_field_value) {
					gregorian_date_field.set_value("");
					return;
				}

				// set gregorian date field based on hijri date
				MedadUtils.set_gregorian_field_from_hijri_value(gregorian_date_field, hijri_date_field_value);
				frm.refresh_fields();
			}

			// assign function to the fields
			frappe.ui.form.on(frm.doctype, gregorian_date_field_name, gregorian_date_function);
			frappe.ui.form.on(frm.doctype, hijri_date_field_name, hijri_date_function);
		});
    },
    child_on_date_hijri: function(frm, cdt, cdn, gregorian_date_field_name, cached_child_hijri_dates) {
        // get hijri date field name
        const hijri_date_field_name = gregorian_date_field_name + "_hijri";
        
        const key = cdt + "-" + cdn + "-" + gregorian_date_field_name;
        const row = locals[cdt][cdn];
        if (!row[hijri_date_field_name] || row[hijri_date_field_name] == cached_child_hijri_dates[key]) {
            row[hijri_date_field_name] = cached_child_hijri_dates[key];
        }
        cached_child_hijri_dates[key] = row[hijri_date_field_name];
        const field_type = frappe.get_meta(cdt).fields.filter(e => e.fieldname == hijri_date_field_name)[0].fieldtype;
        let input_format = field_type === "Date" ? "iYYYY-iMM-iDD" : "iDD-iMM-iYYYY";
        row[gregorian_date_field_name] = MedadUtils.convert_from_hijri_to_gregorian(row[hijri_date_field_name], input_format=input_format);
        frm.refresh_fields();
    }, 
    child_on_date_gregorian: function(frm, cdt, cdn, gregorian_date_field_name, cached_child_hijri_dates) {
        // get hijri date field name
        const hijri_date_field_name = gregorian_date_field_name + "_hijri";

        const row = locals[cdt][cdn];
        const field_type = frappe.get_meta(cdt).fields.filter(e => e.fieldname == hijri_date_field_name)[0].fieldtype;
        let output_format = field_type === "Date" ? "iYYYY-iMM-iDD" : "iDD-iMM-iYYYY";
        row[hijri_date_field_name] = MedadUtils.convert_from_gregorian_to_hijri(locals[cdt][cdn][gregorian_date_field_name], output_format=output_format);
        const key = cdt + "-" + cdn + "-" + gregorian_date_field_name;
        cached_child_hijri_dates[key] = row[hijri_date_field_name];
        frm.refresh_fields();
    },
    doctype_onload_post_render: function(frm, child_doctype, child_table_field_name, gregorian_date_field_name, cached_child_hijri_dates) {
        // get hijri date field name
        const hijri_date_field_name = gregorian_date_field_name + "_hijri";

        const data = frm.get_field(child_table_field_name).grid.data;
        for (let i = 0; i < data.length; i++) {
			const row_name = data[i].name;
			const date_hijri = data[i][hijri_date_field_name];
            const key = child_doctype + "-" + row_name + "-" + gregorian_date_field_name
			cached_child_hijri_dates[key] = date_hijri;
 		}
		frm.refresh_fields();
    },
    doctype_before_load: async function(frm, child_doctype, gregorian_date_field_name, child_table_name) {
        // get hijri date field name
        const hijri_date_field_name = gregorian_date_field_name + "_hijri";
        
        // hide fields depending on the current calendar type
        const is_hijri = await MedadUtils.is_hijri();
		if (is_hijri) {
			var df =  frappe.meta.get_docfield(child_doctype, gregorian_date_field_name, frm.doc.name);
            df.read_only = 1;
            await frm.fields_dict[child_table_name].grid.update_docfield_property(gregorian_date_field_name, "read_only", true)
		} else {
			var df =  frappe.meta.get_docfield(child_doctype, hijri_date_field_name, frm.doc.name);
            df.read_only = 1;
            await frm.fields_dict[child_table_name].grid.update_docfield_property(hijri_date_field_name, "read_only", true)
		}
		frm.refresh_fields();
    },
    // to be added on: onload_post_render of the doctype for each datetime field
    enable_datetime_with_hijri_onload_post_render: function(frm, datetime_field_name, date_field_name, hour_field_name) {
        frappe.ui.form.on(frm.doctype, date_field_name, () => MedadUtils.enable_datetime_on_date(frm, datetime_field_name, date_field_name, hour_field_name));
        frappe.ui.form.on(frm.doctype, hour_field_name, () => MedadUtils.enable_datetime_on_hour(frm, datetime_field_name, date_field_name, hour_field_name));

        const hijri_date_field_name = date_field_name + "_hijri";
        
        const datetime_field = frm.fields_dict[datetime_field_name];
        const datetime_field_value = frm.doc[datetime_field_name];

        // hide datetime field
        datetime_field.df.hidden = 1;

        if (!datetime_field_value) return

        const datetime_value_splitted = datetime_field_value.split(" ");

        const date_value = datetime_value_splitted[0];
        const hijri_date_value = MedadUtils.convert_from_gregorian_to_hijri(date_value);
        const hour_value = datetime_value_splitted[1].slice(0, 8);
        
        frm.fields_dict[date_field_name].set_value(date_value);
        frm.fields_dict[hijri_date_field_name].set_value(hijri_date_value);
        frm.fields_dict[hour_field_name].set_value(hour_value);
        
        frm.refresh_fields();
        // frm.save();
    },
    enable_datetime_on_date: function(frm, datetime_field_name, date_field_name, hour_field_name) {
        const datetime_field = frm.fields_dict[datetime_field_name];
        // const date_field = frm.fields_dict[date_field_name];
        const date_field_value = frm.doc[date_field_name];
        const hour_field = frm.fields_dict[hour_field_name];
        const hour_field_value = frm.doc[hour_field_name];
        
        if (!date_field_value) {
            datetime_field.set_value("");
            hour_field.set_value("");
            return;
        } 

        let datetime_value = date_field_value;
        if (hour_field_value) {
            datetime_value += " " + hour_field_value.slice(0, 8);
        } else {
            datetime_value += " 00:00:00";
        }

        datetime_field.set_value(datetime_value);
    },
    enable_datetime_on_hour: function(frm, datetime_field_name, date_field_name, hour_field_name) {
        // get fields
        const datetime_field = frm.fields_dict[datetime_field_name];
        // const date_field = frm.fields_dict[date_field_name];
        const date_field_value = frm.doc[date_field_name];
        const hour_field = frm.fields_dict[hour_field_name];
        const hour_field_value = frm.doc[hour_field_name];
        
        if (!date_field_value && !hour_field_value) {
            datetime_field.set_value("");
            return;
        }

        if (!date_field_value) {
            hour_field.set_value("");
            frappe.throw(__("Please select the date first"));
        }

        if (!hour_field_value) {
            const datetime_value = date_field_value + " 00:00:00";
            datetime_field.set_value(datetime_value);
            return;
        }
        
        const datetime_value = date_field_value + " " + hour_field_value.slice(0, 8);
        datetime_field.set_value(datetime_value);
    },
    update_child_table_field_options: function(frm, child_table_name, field_name, options) {
        /* Update the select options in the child table field
    
        Args:
            frm: the current form
            child_table_name: the name of the child table
            field_name: the name of the field in the child table
            options: list of string options to set in the field
        */
        let field_options = []
        options.forEach(option => {
            field_options.push({label: option, value: option});
        });
    
        if (frm.fields_dict[child_table_name].grid.user_defined_columns && frm.fields_dict[child_table_name].grid.user_defined_columns.length) {
            frm.fields_dict[child_table_name].grid.user_defined_columns.find((d) => d.fieldname === field_name)['options'] = field_options;
        }
        frm.fields_dict[child_table_name].grid.update_docfield_property(field_name, "options", field_options);
    }
};

